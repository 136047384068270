.table {
    width: 100%;
}

.table-fixed {
    table-layout: fixed;
}

.td-single-line {
    width: 1%;
}

.table-spaced {
    td {
        padding-left: setting(spacing, inline);
    }

    tr td:first-child {
        padding-left: 0;
    }
}

// Disable some lint rules for necessary overriding Bootstrap styling
// stylelint-disable selector-max-compound-selectors
.table-clean > tbody > tr > td,
.table-clean > tbody > tr > th,
.table-clean > tfoot > tr > td,
.table-clean > tfoot > tr > th,
.table-clean > thead > tr > td,
.table-clean > thead > tr > th {
    border: 0;
    padding: 0;
}

.table > tbody > tr > .td-indented {
    padding-left: setting(spacing, element);
}
// stylelint-enable selector-max-compound-selectors

.table-header-filters {
    display: table-row-group;
}

.table-form .form-group {
    margin-bottom: 0;
}

.table-form .control-label {
    @include sr-only;
}

.table-heading-short-number {
    width: $form-number-calculation;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-heading-short-number-static {
    width: $form-number-max-numbers-width;
}
