$navbar-sidebar-width: 10vw;
$navbar-margin-bottom: setting(spacing, element);
$container-padding-lr: 60px;

.notification {
    position: fixed;
    top: calc($navbar-height + #{$navbar-margin-bottom});

    width: calc(100% - (#{$container-padding-lr} / 2));
    z-index: 2;

    @media (min-width: $screen-md-min) {
        width: calc(100% - #{$navbar-sidebar-width} - #{$container-padding-lr});
    }
}
