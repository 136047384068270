@media (min-width: $screen-md-min) {
    .time-row {
        display: flex;
        justify-content: space-between;
    }

    .time-row-picker {
        display: flex;
        flex: 0 0 auto;

        > .form-group:not(:last-child) {
            margin-right: setting(spacing, element);
        }
    }
}

.time-row-context-checkbox {
    display: flex;
}

.time-row-help-block {
    padding-left: setting(spacing, inline);
    padding-right: setting(spacing, inline);
}
