.sp-replacer {
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    height: $input-height-base;
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;

    &:hover {
        border-color: $input-border;
    }

    &.sp-active {
        border-color: $input-border-focus;
    }
}

.sp-preview {
    height: $line-height-computed;
    border: 0;
}

.sp-dd {
    color: $s2bs-dropdown-arrow-color;
}

.sp-container {
    border: 1px solid $input-border;
    background-color: $input-bg;
    border-color: $input-border-focus;
}

.sp-palette .sp-thumb-el.sp-thumb-active,
.sp-palette .sp-thumb-el:hover {
    border-color: $input-border-focus;
}

.sp-palette .sp-thumb-el {
    height: $line-height-computed;
    width: $line-height-computed;
}
