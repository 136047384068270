.stack {
    display: none;

    pre {
        width: 100%;
        max-width: 500px;
        max-height: $pre-scrollable-max-height;
        overflow: scroll;
    }
}
