$form-number-max-numbers-width: 6ch;
$form-number-calculation: calc(#{$form-number-max-numbers-width} + #{$padding-base-horizontal * 2});

.form-control {
    font-weight: 400;
}

// Overwrite bootstrap nested styling.
// stylelint-disable declaration-no-important
.form-control-number {
    min-width: $form-number-calculation !important;
}
// stylelint-enable
