@media (min-width: $screen-md-min) {
    .repeat-row {
        display: flex;
        justify-content: space-between;

        .repeat-row-context {
            width: 45%;
        }
    }
}
