.actions {
    @media (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }
}

.actions-bordered {
    border-bottom: setting(border, default);
    padding-bottom: setting(spacing, element-narrow);
}

.actions-start {
    justify-content: flex-start;
    align-items: center;
}

.actions-between {
    justify-content: space-between;
    align-items: center;
}

.actions-date-nav {
    @media (min-width: $screen-md-min) {
        margin-right: setting(spacing, element-narrow);
    }
}
