$logo-height: 20px;
$logo-height-larger: 30px;

.logo {
    height: $logo-height;
}

.navbar-brand {
    @include navbar-padding($logo-height);

    .logo {
        height: $logo-height;
    }
}

.navbar-brand-larger-logo {
    @include navbar-padding($logo-height-larger);

    .logo {
        height: $logo-height-larger;
    }
}
