/*
 * Create a helper class for setting an auto margin for every direction.
 * Usefull for aligning an odd element with flexbox
 */
$values: (top, right, bottom, left);

@each $value in $values {
    .u-margin-#{$value}-auto {
        margin-#{$value}: auto;
    }
}
