.copyright {
    @media (max-width: $screen-sm-max) {
        text-align: center;
    }

    @media (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }
}
