$hours-per-day: 24;
$slots-per-hour: 4;
$cell-min-width: 6ch;
$day-table-ticker: 2px;

.day-table {
    display: flex;
    border-right: setting(border, default);
}

.day-table__headings {
    flex-shrink: 0;
    text-align: right;

    border-right: setting(border, default);
}

/**
 * [1] Make all the numbers the same with.
 */
.day-table__heading {
    padding-left: setting(spacing, inline);
    padding-right: setting(spacing, inline);

    font-variant-numeric: tabular-nums; /*[3]*/
}

.day-table__table {
    position: relative;

    flex: 1 0;

    overflow-x: auto;
}

.day-table__hours {
    display: flex;
    justify-content: space-between;
}

/**
 * [1] The total width of the table divided by the total hours of the table.
 * [2] The min-width is 6 characters so it always fits 00:00 and some spacing.
 * [3] Make all the numbers the same with.
 */
.day-table__hour {
    width: calc(100% / #{$hours-per-day}); /*[1]*/

    min-width: $cell-min-width; /*[2]*/

    border-top: setting(border, default);
    border-bottom: setting(border, default);
    border-right: setting(border, default);

    font-variant-numeric: tabular-nums; /*[3]*/

    z-index: setting(z-index, table-header);

    text-align: center;

    &:last-child {
        border-right: 0;
    }
}

/**
 * [1] Add a float so the width of the row stays the total width of all the cells.
 */
.day-table__row {
    position: relative;

    display: flex;
    justify-content: space-between;

    float: left; /*[1]*/

    min-width: 100%;
}

.day-table__slot {
    width: calc(100% / (#{$hours-per-day} * #{$slots-per-hour}));
    min-width: $cell-min-width / $slots-per-hour;
    height: $line-height-computed;
    cursor: default;

    background-color: setting(color, gray-lighter);

    border-right: setting(border, default);
    border-bottom: setting(border, default);
    cursor: pointer;

    &:last-child {
        border-right: 0;
    }

    &.is-optional {
        background-color: setting(color, white);
    }
}

.day-table__ticket {
    $ticket-border-size: setting(border-width, default);

    position: absolute;
    top: 0;

    height: calc(100% + #{$ticket-border-size});
    width: auto;

    margin-top: -$ticket-border-size;
    margin-left: -$ticket-border-size;

    padding-left: setting(spacing, inline);
    padding-right: setting(spacing, inline);

    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:active,
    &:focus {
        border-color: setting(border-color, table-ticket-hover);
        z-index: setting(z-index, ticket-hover);
    }
}

.day-table__ticker {
    position: absolute;

    height: 100%;
    width: $day-table-ticker; /* [1] */
    margin-left: ($day-table-ticker / 2) * -1;

    background-color: setting(color, ticker);

    overflow: hidden;
    font-size: 0;

    top: 0;
    z-index: setting(z-index, ticker);
}

.day-table__link {
    text-decoration: underline;
}
