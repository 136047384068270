.element {
    @include element;
}

.element-narrow {
    @include element('narrow');
}

.element-context {
    @include element('narrow', 'right');
}
