@media (min-width: $screen-md-min) {
    .day-overview {
        display: flex;
        width: 100%;
    }

    .day-overview__main {
        flex: 1 1 auto;
        overflow-x: auto;
    }

    .day-overview__sidebar {
        flex: 0 0 percentage(1/3);
        max-width: calc(#{percentage(1/3)} - #{setting(spacing, element)});
    }
}

.day-overview__sidebar {
    padding: setting(spacing, element);
    margin-left: setting(spacing, element);

    background-color: $table-bg-accent;
}
