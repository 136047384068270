.license-fail .license-alert-fail,
.license-wait .license-alert-checking,
.license-ok .license-alert-ok {
    display: block;
}

.license-ok {
    .license-input {
        border-color: setting(color, default-theme-color);
        background-image: url('../images/check.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - #{$padding-base-horizontal}) 50%;
    }

    .license-input:focus {
        border-color: setting(color, default-theme-color);
        box-shadow: none;
    }
}

.license-alert-ok,
.license-alert-checking,
.license-alert-fail {
    display: none;
}
