.btn-filter {
    @include button-variant(
        setting(color, white),
        setting(color, dark),
        setting(color, dark)
    );
}

.btn-no-border {
    @include bs-button-padding-no-border($padding-base-vertical, $padding-base-horizontal);

    border: 0;
}

.btn-block + .dropdown-menu {
    min-width: 100%;
}

.btn-link-tight {
    padding-right: 0;
    padding-left: 0;
}

.btn-arranged {
    .btn + .btn {
        margin-left: setting(spacing, inline);
    }
}
