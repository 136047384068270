/*
 * Clearfix
 * With option to clearfix before an element
 */
@mixin clearfix($placement: after) {

    $possible: before after;

    @if index($possible, $placement) {
        &:#{$placement} {
            content: '';
            display: table;
            clear: both;
        }
    } @else {
        @warn 'D’oh! ‘#{$placement}’ is not a valid value for the clearfix. Only after or before are valid.';
    }

}

/*
 * Sr-only
 * Copy/paste from Bootstrap as a mixin.
 */
@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
