/**
 * Add a border so the default Bootstrap labels are the same height as the custom
 * labels.
 */
.label {
    font-weight: setting(font-weight, normal);
    font-size: $font-size-small;
    border: solid setting(border-width, label); /* [1] */
}

.label-full {
    display: inline-block;
    width: 100%;
}

$bootstrap-labels: (
    default: $label-default-bg,
    primary: $label-default-bg,
    success: $label-default-bg,
    info: $label-default-bg,
    warning: $label-default-bg,
    danger: $label-default-bg
);

@each $key, $color in $bootstrap-labels {
    .label-#{$key} {
        border-color: $color;
    }
}

/**
 * Custom labels for each status.
 */

.label-custom-unknown {
    border-color: setting(color, label-unknown);
    color: setting(color, label-unknown);
}

.label-custom-to_stage_location {
    border-color: setting(color, label-wait-room);
    color: setting(color, label-wait-room);
}

.label-custom-at_stage_location {
    border-color: setting(color, label-wait-room);
}

.label-custom-to_site {
    border-color: setting(color, label-site);
    color: setting(color, label-site);
}

.label-custom-on_site {
    border-color: setting(color, label-site);
}

.label-custom-was_on_site {
    border-color: setting(color, label-done);
}

.label-custom-no_show {
    border-color: setting(color, label-never-done);
}

.label-custom-canceled {
    border-color: setting(color, label-canceled);
    color: setting(color, label-canceled);
}

/**
 * Custom statuses
 */
.label-custom-requested,
.label-custom-accepted,
.label-custom-approved,
.label-custom-done,
.label-custom-cancelled,
.label-custom-denied,
.label-custom-disapproved,
.label-custom-success,
.label-custom-pending,
.label-custom-failed {
    color: setting(color, white);

    border: setting(border, table-ticket);
    box-shadow: setting(box-shadow, default);

    text-shadow: setting(text-shadow, default);

    // Magic but copy/paste from Boostrap hard coded values
    padding: calc(0.2em + #{setting(border-width, default)}) 0.6em
        calc(0.3em + #{setting(border-width, default)});
}

.label-custom-success,
.label-custom-pending,
.label-custom-failed {
    display: block;
    width: 100%;
}

/* status datatable background */
.status-label.label-custom-at_stage_location,
.status-label.label-custom-failed {
    background-color: setting(color, label-wait-room);
}

.status-label.label-custom-on_site {
    background-color: setting(color, label-site);
}

.status-label.label-custom-was_on_site {
    background-color: setting(color, label-done);
}

.status-label.label-custom-no_show {
    background-color: setting(color, label-never-done);
}

.status-label.label-custom-requested,
.status-label.label-custom-pending {
    @include striped-background(setting(color, label-site), $b / 2);
}

.status-label.label-custom-accepted,
.status-label.label-custom-approved,
.status-label.label-custom-success {
    background-color: setting(color, label-site);
}

.status-label.label-custom-done {
    background-color: setting(color, label-done);
}

.status-label.label-custom-cancelled,
.status-label.label-custom-denied,
.status-label.label-custom-disapproved {
    background-color: setting(color, label-never-done);
}
