// Because of Bootstrap we need to be really specific
// stylelint-disable selector-max-specificity

$bootstrap-default-dropdown-vertical-padding: 3px;

.dropdown-menu-static {
    position: static;
    width: 100%;
}

.dropdown-menu-sidebar {
    background-color: transparent;
    border: 0;
    padding: 0;

    > li {
        $link-padding: $bootstrap-default-dropdown-vertical-padding;

        position: relative;

        min-height: calc(#{$b} + #{$link-padding * 2});
    }

    > li > a {
        position: absolute;
        width: 100%;
        max-width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: setting(color, gray-lighter);
    }

    > li > a:hover,
    > li > a:active,
    > li > a:focus,
    > .active > a,
    > .active > a:hover,
    > .active > a:active,
    > .active > a:focus {
        background-color: setting(color, sidebar-hover);
        color: setting(color, gray-lighter);
    }
}
// stylelint-enable selector-max-specificity
