// Disable some lint rules for necessary overriding third-party styling
// stylelint-disable selector-no-qualifying-type, declaration-no-important
.table .form-control {
    // a hack for responsive tables
    width: 100% !important;
}

// replace DT spacings with our own (saner) spacings
table.dataTable,
.dt-buttons,
.dataTables_filter,
.dataTables_paginate,
.dt-buttons,
.dataTables_info,
.dataTables_length {
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: setting(spacing, element) !important;

    label {
        margin-bottom: 0 !important;
    }
}

//Add Datatables styling on the correct elements.
.dataTables_wrapper {
    .dropdown-menu > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap;
    }

    .dropdown-menu > .active {
        color: #fff;
        text-decoration: none;
        outline: 0;
        background-color: setting(color, datatables-gray);
    }
}

/**
 * Center the actions on xs & sm screens.
 */
@media (max-width: $screen-sm-max) {
    .datatables-actions,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center;
    }
}

/**
 * Place the pagination under the export & lenght actions.
 */
@media (min-width: $screen-md-min) {
    .dataTables_length {
        margin-right: setting(spacing, element-narrow);
    }

    .dataTables_length::after {
        padding-left: setting(spacing, element-narrow);
        color: $table-border-color;
        content: '|';
    }

    .datatables-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .datatables-actions-end {
        justify-content: flex-end;
    }

    .datatables-length {
        display: flex;
        align-self: center;
    }
}

// stylelint-enable selector-no-qualifying-type, declaration-no-important
