.sidebar {
    position: relative;
}

.sidebar__btn {
    position: absolute;
    top: 0;
    right: 0;
}

.sidebar-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
