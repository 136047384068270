@media (min-width: $screen-md-min) {
    .week-overview-split {
        .week-overview {
            display: flex;
            flex-wrap: wrap;

            margin-left: -$b;
        }

        .week-overview__table {
            width: percentage(1/2);
            padding-left: $b;
        }
    }
}

.week-overview__table.is-active {
    .week-overview__title {
        color: setting(color, ticker);
        font-weight: setting(font-weight, bolder);
    }

    .day-table__table {
        border: solid setting(border-width, week) setting(color, ticker);
    }


    .day-table__hour {
        border-bottom-color: setting(color, ticker);
    }
}
