/**
 * Color idea from: https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
 */
$swatches: (
    aqua: #ccf1ff,
    black: #373737,
    blue: #0074d9,
    blue-alt: #279aff,
    brown: #886b44,
    brown-alt: #c69f6b,
    fuchsia: #f012be,
    gold: #c0b283,
    gray: #aaa,
    green: #2ecc40,
    lime: #01ff70,
    lime-alt: #4eff9b,
    maroon: #85144b,
    maroon-alt: #c81e71,
    navy: #00458c,
    olive: #3d9970,
    olive-alt: #62c096,
    orange: #ff851b,
    orange-alt: #ffae68,
    purple: #b10dc9,
    purple-alt: #d931f2,
    red: #ff4136,
    red-alt: #ff8983,
    silver: #ddd,
    silver-alt: #fff,
    teal: #39cccc,
    yellow: #ffdc00,
    yellow-alt: #fcf6d6,
);

.swatch {
    display: inline-block;
    width: $line-height-computed;
    height: $line-height-computed;
    border-radius: setting(border-radius, circle);

    border: solid setting(border-width, default) rgba(0, 0, 0, 0.1);
}

.swatch-sm {
    width: $line-height-computed / 2;
    height: $line-height-computed / 2;
}

@each $key, $color in $swatches {
    .swatch-#{$key} {
        background-color: $color;
    }

    .swatch-#{$key}-alt {
        background-color: lighten($color, 15%);
    }

    .swatch-#{$key}-striped {
        $stripe-width: $b / 2;

        @include striped-background($color, $stripe-width);
    }
}
