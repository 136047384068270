.is-required .control-label::after {
    $asterisk-text-offset: 5px;

    color: setting(color, brand-primary);
    content: '*';
    padding-left: $asterisk-text-offset;
}

.form-control-invert {
    background-color: setting(color, dark);
    border-color: setting(color, dark);

    color: setting(color, white);

    &::placeholder {
        color: setting(color, light);
    }
}

.form-control-round {
    border-radius: setting(border-radius, circle);
}

/**
 * Show 2 select's with hours/minutes divided by a divider.
 */
.form-wrapper-time {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .form-control {
        flex: 1 1;
    }
}

.form-control-divider {
    padding-left: setting(spacing, inline);
    padding-right: setting(spacing, inline);
}

.form-quick-add {
    display: flex;
    align-items: center;

    .form-group {
        flex: 1 1 auto;
        margin-bottom: 0;
        margin-right: setting(spacing, element-narrow);
    }
}

.form-container-combined {
    display: flex;
    align-items: flex-end;
}

.form-container-combined-seperator {
    padding: $padding-base-vertical $padding-base-horizontal;
    margin-bottom: $form-group-margin-bottom;
}

.form-control-tabular-nums {
    font-variant-numeric: tabular-nums;
}

.form-labels-inline {
    display: flex;
    justify-content: space-between;
}
