@mixin element ($variation: false, $position: bottom) {
    $possible: narrow;
    $possible-position: top right bottom left;
    $spacing: setting(spacing, element);

    @if $variation and not index($possible, $variation) {
        @warn 'D’oh! ‘#{$variation}’ is not a valid value for the element mixin. Please try again.';
    }

    @if $position and not index($possible-position, $position) {
        @warn 'D’oh! ‘#{$variation}’ is not a valid value for the element mixin. Please try again.';
    }

    @if $variation == narrow {
        $spacing: setting(spacing, element-narrow);
    }

    margin-#{$position}: $spacing;
}
