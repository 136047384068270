$stream-gap: setting(spacing, element-narrow);

.streams {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.streams-item {
    margin-bottom: $stream-gap;
    width: 100%;

    > iframe {
        display: block;
        background-color: setting(color, black);
    }
}

@media (min-width: $screen-md-min) {
    .streams {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -$stream-gap;
    }

    .streams-item {
        margin-left: $stream-gap;
        width: calc(#{percentage(1/4)} - #{$stream-gap});
    }
}
