.btn-link {
    &.btn-success {
        color: $state-success-text;
    }

    &.btn-info {
        color: $state-info-text;
    }

    &.btn-warning {
        color: $state-warning-text;
    }

    &.btn-danger {
        color: $state-danger-text;
    }
}
