// Disable some lint rules for necessary overriding Bootstrap styling
// stylelint-disable selector-max-specificity, max-nesting-depth, selector-max-compound-selectors

/*
 * Reset
 */
.navbar-sidebar {
    .navbar-nav,
    .navbar-nav > li,
    .navbar-left,
    .navbar-right,
    .navbar-header {
        float: none;
    }

    .navbar-right .dropdown-menu {
        left: 0;
        right: auto;
    }

    .navbar-collapse .navbar-nav.navbar-right:last-child {
        margin-right: 0;
    }
}

/**
 * [1] Magic Bootstrap height.
 */
.navbar .divider {
    height: 1px; /* [1] */
    overflow: hidden;
    background-color: setting(color, sidebar-divider);
}

/*
 * Theming
 *
 * Magic but gives the navbar the space so the app looks more balanced.
 */
.navbar-sidebar {
    border: 0;
    min-width: 10vw; /* [1] */

    .navbar-nav {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .navbar-nav > li > a {
        color: setting(color, gray-lighter);
    }


    .navbar-nav .open > a,
    .navbar-nav .open > a:focus,
    .navbar-nav .open > a:hover,
    .navbar-nav > li > a:hover,
    .navbar-nav > li > a:active,
    .navbar-nav > li > a:focus,
    .navbar-nav > .active > a {
        background-color: setting(color, sidebar-hover);
    }
}

.navbar-filters {
    display: flex;

    position: sticky;
    top: 0;
    z-index: setting(z-index, filters);

    min-height: $navbar-height;

    padding-left: $navbar-padding-horizontal;
    padding-right: $navbar-padding-horizontal;
    margin-bottom: $navbar-padding-vertical;

    background-color: $brand-primary;
}

.navbar-default {
    margin-bottom: $navbar-padding-vertical;
    background-color: setting(color, default-theme-color);

    .navbar-header {
        background-color: setting(color, default-theme-color);
        display: flex;
        justify-content: space-between;
        align-items: center;

        /**
         * Unset the default Bootstrap navbar clearfix
         */
        &::before,
        &::after {
            content: none;
        }
    }

    /**
     * Mimic the default Bootstrap container spacing.
     */
    .navbar-header-actions {
        margin-right: ($grid-gutter-width / 2);
    }

    .divider {
        background-color: setting(color, gray-light);
    }
}

.navbar-header {
    background-color: $brand-primary;
}

.navbar-brand {
    display: inline-block;

    &,
    &.active,
    &:hover,
    &:active
    &:focus {
        background-color: setting(color, dark);
    }

    @media (min-width: $screen-md-min) {
        display: block;
        float: none;
    }
}
// stylelint-enable selector-max-specificity, max-nesting-depth, selector-max-compound-selectors
