.page-header-entity {
    @media (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }
}

.page-header-entity-flex {
    display: flex;
    justify-content: space-between;
}

.page-header-right {
    align-items: flex-end;
}

/*
 * The action buttons are the most import of the page header, make sure
 * the buttons are on one line when flexed
 */
.page-header-actions {
    flex-shrink: 0;
}

/**
 * Reset the vertical align of buttons so they align with other elements like
 * dropdowns.
 */
.page-header-actions .btn {
    vertical-align: inherit;
}
