// Because of Bootstrap we need to be really specific
// stylelint-disable selector-max-specificity, selector-no-qualifying-type
$nav-tabs-border-width: 2px;

.nav-tabs {
    border-bottom-width: $nav-tabs-border-width;

    > li {
        margin-bottom: -$nav-tabs-border-width;
    }
}

/**
 * Reset default bootstrap styles.
 */
.nav-tabs > li > a {
    padding-left: 0; /* [1] */
    padding-right: 0; /* [1] */

    margin-right: setting(spacing, element-narrow);

    border: 0; /* [1] */
    border-bottom: solid $nav-tabs-border-width transparent;
}

/**
 * Reset default bootstrap styles.
 */
.nav-tabs > li > a:hover,
.nav-tabs > li > a:active,
.nav-tabs > li > a:focus,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    background-color: transparent; /* [1] */
    border-top: 0; /* [1] */
    border-right: 0; /* [1] */
    border-left: 0; /* [1] */

    border-bottom: solid $nav-tabs-border-width setting(color, brand-primary);
}
// stylelint-enable
