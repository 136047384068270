@media (min-width: $screen-md-min) {
    .app {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100vh;

        overflow: hidden;
    }

    .app-navbar {
        flex-shrink: 0;
        height: 100%;

        overflow-y: auto;
    }

    .app-main {
        flex: 1 0;

        overflow-y: auto;
    }

    .app-navbar {
        display: flex;
        flex-direction: column;

        background-color: setting(color, sidebar-background);

        a {
            white-space: nowrap;
        }
    }
}
