/*
    The x-editable library set a `display: none` on the `.datepicker > div` selector,
    so we need to gently overwrite it
*/

/* stylelint-disable selector-max-compound-selectors, selector-max-specificity, declaration-no-important, max-nesting-depth */

._fix-datepicker + .bootstrap-datetimepicker-widget {
    .datepicker > div {
        display: block;
    }
}

/**
 * This is a hack to allow a form button to be placed in a dropdown menu and keep the styling looking consistant.
 *
 * The styling is copied from the bootstrap "_dropdown.scss"
 * And the responsive code comes from the bootstrap "_navbar.scss"
 */
._dropdown-form-button {
    border: 0;
    text-align: left;
    width: 100%;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color !important;
    white-space: nowrap; // prevent links from randomly breaking onto new lines

    &:hover,
    &:focus {
        text-decoration: none;
        color: $dropdown-link-hover-color !important;
        background-color: $dropdown-link-hover-bg;
    }

    @media (max-width: $grid-float-breakpoint-max) {
        padding: 5px 15px 5px 25px;
        line-height: $line-height-computed;
        color: $navbar-default-link-color !important;

        &:hover,
        &:focus {
            color: $navbar-default-link-hover-color !important;
            background-color: $navbar-default-link-hover-bg;
        }
    }
}

._dropdown-form-button-invert {
    color: setting(color, white) !important;
    background-color: transparent;

    &:hover {
        background-color: setting(color, sidebar-hover);
        color: setting(color, white) !important;
    }
}

._link-underline {
    text-decoration: underline;
}

.row-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* stylelint-enable selector-max-compound-selectors, selector-max-specificity, declaration-no-important, max-nesting-depth */
