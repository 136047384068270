/*
 * # Tools
 * Abstract functions for  getting or modifying data
 */
@import 'tools/functions';
@import 'tools/lengths';
@import 'tools/svg';
/*
 * # Settings
 * All the project settings
 */
@import 'settings/private';
@import 'settings/colors';
@import 'settings/font';
@import 'settings/spacing';
@import 'settings/border';
@import 'settings/z-index';
@import 'settings/settings';
@import 'settings/bootstrap';
@import 'settings/bootstrap-theme';
/*
 * # Frameworks
 * Include Bootstrap
 */
@import 'bootstrap-sass/assets/stylesheets/bootstrap';

$b: $line-height-computed;

/*
 * # Vendor
 * External packages
 */
@import 'font-awesome/scss/font-awesome';
@import 'datatables.net-bs4/css/dataTables.bootstrap4';
@import 'datatables.net-buttons-bs4/css/buttons.bootstrap4';
@import 'datatables.net-responsive-dt/css/responsive.dataTables';
@import 'datatables.net-responsive-bs4/css/responsive.bootstrap4';
@import 'select2/src/scss/core';
@import 'select2-bootstrap-theme/src/select2-bootstrap';

$pd-font-family: $font-family-base;
$pd-day-hover-bg: $brand-primary;
$pd-day-today-color: $pd-day-hover-bg;
$pd-day-selected-bg: $pd-day-hover-bg;
@import 'pikaday/scss/pikaday';
@import 'spectrum-colorpicker/spectrum';
@import 'summernote/dist/summernote';

/*
 * # Mixins
 * Mixins that generate css that can be used in other files
 */
@import 'mixins/element';
@import 'mixins/utilities';
@import 'mixins/button';
@import 'mixins/navbar-padding';
@import 'mixins/striped-background';

/*
 * # Generic
 * Styling for all elements, like resets or normalizing
 */
@import 'generic/reset-bootstrap';

/*
 * # Elements
 * Base styling for some elements like tables, headings or images
 */
@import 'elements/img';
@import 'elements/element';
@import 'elements/flush';
@import 'elements/forms';
@import 'elements/table';

/*
 * # Components
 * All custom components like a menu, dashboard or buttons
 */
@import 'components/app';
@import 'components/btn';
@import 'components/btn-link';
@import 'components/page';
@import 'components/copyright';
@import 'components/datatable';
@import 'components/form';
@import 'components/panel';
@import 'components/breadcrumb';
@import 'components/license';
@import 'components/logo';
@import 'components/stack';
@import 'components/navbar';
@import 'components/page-header';
@import 'components/dropdown';
@import 'components/nav';
@import 'components/swatch';
@import 'components/spectrum-theme';
@import 'components/day-overview';
@import 'components/day-table';
@import 'components/week-overview';
@import 'components/dropdown-menu';
@import 'components/repeat-row';
@import 'components/time-row';
@import 'components/sidebar';
@import 'components/label';
@import 'components/streams';
@import 'components/actions';
@import 'components/well-actions';
@import 'components/weerplaza-widget';
@import 'components/notification';

/*
 * # Theming
 * All themeable elements, can be overwritten by the customer.
 */
.t-navbar-header,
.t-navbar-filters {
    background-color: setting(color, default-theme-color);
}

/*
 * # Utilities
 * Very specific helper styling that are easy to use and have a simple purpose
 * like clearfix or hacks
 */
@import 'utilities/hacks-bootstrap';
@import 'utilities/wordbreak';
@import 'utilities/position';
@import 'utilities/auto-margin';
@import 'utilities/general';
@import 'utilities/display';
