.panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel-body {
    background-color: setting(color, white);
}

.panel-footer {
    background-color: setting(color, gray-lighter);
}

.panel-terms a {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}
